@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "@angular/material/theming";

$custom-typography: mat-typography-config($font-family: "Public Sans",
    );

$my-own-theme: (
    100:#FFFFFF,
    500: #ffffff,
    700: #ffffff,
    contrast: (500: #ffffff,
        100:#FFFFFF,
        700: #ffffff,
    ),
);

$my-own-accent: (
    100: #000000,
    500: #000000,
    700: #000000,
    contrast: (
        100: #ffffff,
        500: #ffffff,
        700: #ffffff,
    ),
);
$my-own-red: (
    100: #ff4242,
    500: #ff4242,
    700: #ff4242,
    contrast: (
        100: #ffffff,
        500: #ffffff,
        700: #ffffff,
    ),
);

@include mat-core($custom-typography);
$my-app-primary: mat-palette($my-own-theme, 500);
$my-app-accent: mat-palette($my-own-accent, 500);
$my-app-warn: mat-palette($my-own-red);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);

// Variables
$font-name: "Public Sans", sans-serif;

$color-black: #000000;
$color-white: #ffffff;
$color-background: #f8f8f8;
$color-primary: #f7941d;
$color-primary-light: #828282;
$color-secondary: #5d83a1;
$color-accent: #000000;
$color-accent-light: #eae8e8;
$color-border: #e0dbdb;
$color-light-gray: #F2F2F2;

$color-black-100: #232323;
$color-black-200: #555555;
$color-black-300: #999999;
$color-black-400: #d9d9d9;
$color-white-100: #ffffff;
$color-white-200: #ffffff;

$color-green: #219653;
$color-light-green: #cdffdb;
$color-red: #ff4242;
$color-light-red: #ffdada;
$color-blue: #1c75bc;
$color-light-blue: #cae7ff;
$color-yellow: #ffa200;

$weight-300: 300;
$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;
$weight-800: 800;
$weight-900: 900;

$size-10: 10px;
$size-11: 11px;
$size-12: 12px;
$size-13: 13px;
$size-14: 14px;
$size-15: 15px;
$size-16: 16px;
$size-17: 17px;
$size-18: 18px;
$size-19: 19px;
$size-20: 20px;

@mixin transition($transitioneffects) {
    -webkit-transition: $transitioneffects !important;
    -moz-transition: $transitioneffects !important;
    -o-transition: $transitioneffects !important;
    transition: $transitioneffects !important;
}

@mixin transform($transformeffects) {
    -webkit-transform: $transformeffects !important;
    -moz-transform: $transformeffects !important;
    -o-transform: $transformeffects !important;
    transform: $transformeffects !important;
}

@mixin box-shadow($boxshadoweffects) {
    -webkit-box-shadow: $boxshadoweffects !important;
    -moz-box-shadow: $boxshadoweffects !important;
    -o-box-shadow: $boxshadoweffects !important;
    box-shadow: $boxshadoweffects !important;
}
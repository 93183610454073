/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import "variables";

// *{
//     margin: 0;
//     padding: 0;  
// }
* {
    font-family: 'Public Sans', sans-serif;
}


html,
body {
    height: 100%;
    // font-family: Public, "Public Sans", sans-serif;
}

body {
    margin: 0;

}

// Main container
.main-content {
    padding: 60px 0px 14px 0px;
    position: relative;
    min-height: 630px;
    background-color: $color-white;
}

.main-container {
    padding: 50px 0px 44px 0px;
    position: relative;
    min-height: 630px;
    background-color: $color-white;
}


.page-container {
    padding: 8px 16px 10px 16px;
    background-color: $color-white;

    .change {
        color: #1c75bc;
        text-decoration: underline;
    }

    .student-change {
        padding: 8px 16px 8px 16px;
        color: #1c75bc;
        text-decoration: underline;
    }
}

// @media (min-width: 1500px) {

//     // Main container
//     .main-content {
//         padding: 80px 0px 14px 90px;
//         position: relative;
//         min-height: 630px;
//         background-color: $color-white;
//     }

//     .main-container {
//         padding: 50px 0px 44px 20px;
//         position: relative;
//         min-height: 630px;
//         background-color: $color-white;

//     }

// }

// @media (min-width: 1300px) and (max-width: 1500px) {

//     // Main container
//     .main-content {
//         padding: 70px 0px 14px 50px;
//         position: relative;
//         min-height: 630px;
//         background-color: $color-white;
//     }

//     .main-container {
//         padding: 50px 0px 44px 50px;
//         position: relative;
//         min-height: 630px;
//         background-color: $color-white;
//     }


// }


// Home page Layouts
.home-desktop {
    padding: 0 60px;
    max-width: 100px;
    margin: 0 auto;
}

.dash-line {
    border-bottom: 2px dashed $color-border;
    margin-bottom: 10px;
}

.tablet {
    padding: 0 96px;
}

.mobile {
    padding: 0 16px;
}

// Hide section
.hide {
    display: none;
}

.marggin-top {
    margin-top: 10px !important;
}

.marggin-botom {
    margin-bottom: 10px !important;
}

.padding-top {
    padding-top: 10px !important;
}

.padding-botom {
    padding-bottom: 10px !important;
}

.padding-botom20 {
    padding-bottom: 15px !important;
}

// Text alignments
.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;

    button {
        border-radius: 7px !important;
    }
}

// Content alignment
.align-center {
    justify-content: center;
}

.align-left {
    justify-content: flex-start;
}

.align-right {
    justify-content: flex-end;
}

// Text with horizontal line after before
.p-center {
    p {
        display: flex;
        flex-direction: row;
        justify-content: center !important;
        padding-bottom: 5px;
    }

    p:before,
    p:after {
        content: "";
        flex: 1 1;
        border-bottom: 0.1px solid #c6c6c6;
        margin: auto;
    }

}



// Landing page
.banner-background-image {

    // background-image: url("./assets/images/landing.png");
    img {
        background-size: 100%;
        background-position: 0% 10%;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: -1;

    }
}

.banner-background-image-responsive {
    background-size: cover;
    background-position: 0% 0%;
}

.banner-text {
    padding: 70px 0px 0px 170px;
    height: 100%;
    width: 100%;

    .icon {
        width: 200px;
        height: 50px;
    }

    h1 {
        color: var(--Colors-Common-White, #FFF);
        font-size: 50px;
        font-style: normal;
        font-weight: 1;
        line-height: normal;
        padding-top: 110px;
        z-index: 1;
    }

    .example-button-row {
        display: table-cell;
        max-width: 600px;
        margin-top: 40px;

        button {
            padding: 0px 35px 0px 35px;
        }
    }

    .example-button-row .mat-mdc-button-base {
        margin: 8px 8px 8px 0px;

    }

    p {
        padding-top: 20px;
        color: #FFF;
        font-size: 25px;
        font-style: normal;
        font-weight: normal;
        line-height: normal;
    }

    .contact-info {
        color: #FFF;
        font-size: 14px;

        mat-icon {
            font-size: 14px;
            margin: 0px 0px 0px 10px;
        }
    }
}

// Form card
.form-card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0px 7px 0px 7px;

    .form-header {
        padding: 0px 0px 15px 0px;

        h2 {
            font-weight: 600;
            color: var(--black, #000);
            font-size: 16px;
            margin: 0px 0px 5px 0px;

        }

        h3 {
            font-weight: 600;
            color: var(--black, #000);
            font-size: 14px;
            margin: 0px 0px 8px 0px;

        }

        p {
            font-weight: 500;
            color: var(--black, #898888);
            font-size: 11px;
            margin: 3px 0px 0px 3px;

        }

        span {
            font-size: 11px;
            font-weight: 500;

            a {
                color: var(--Blue-1, #2F80ED);
                cursor: pointer;
            }
        }

        .steps {
            font-size: 12px;
            font-weight: 500;
            position: relative;
        }

        .image-size {
            img {
                width: 260px;
                height: 480px;
                background-size: cover;
                border-radius: 5px;
                object-fit: cover;

            }
        }

        .sign-tex {
            padding-bottom: 13px;
        }

        .bg-img {
            background-image: url("./assets/images/login-bigimg.png");
            min-height: 480px;
            width: 260px;
            border-radius: 7px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            object-fit: cover;

            span {
                overflow: hidden;
                color: var(--White, var(--Colors-Common-White, #d6d4d4));
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 36px;
                padding: 15px 0px 0px 12px !important;


            }
        }

        .logo-size {
            img {
                width: 100px;
                padding: 20px 0px 30px 0px;


            }
        }
    }

    .form-body {
        padding: 0px 0px 0px 0px;
        font-size: 11px;

        .label {
            font-size: 12px;
            font-weight: 600;
            color: var(--black, #000);
        }

        mat-radio-group {
            padding: 3px 0px 3px 0px;
            color: var(--black, #000);
            font-size: 10px;
            font-weight: 400;

            .example-radio-button {
                color: var(--black, #000) !important;
                font-size: 12px;
                font-weight: 400;
            }

        }

        .seasons {
            padding-bottom: 15px;
        }

        .field-heading {
            font-size: 11px;
            font-weight: 600;
            padding-bottom: 10px;
        }

        .field-heading02 {
            font-size: 11px;
            font-weight: 600;
            padding: 0px 0px 0px 0px;
        }

    }

    .form-footer {
        display: table-cell;
        max-width: 600px;

        button {
            color: var(--black, #ffffff);
            background-color: #000;
            padding: 0px 35px 0px 35px;
            font-size: 15px !important;
        }

        .mat-icon {
            font-size: 30px;
            margin-right: -120px;
            margin-top: 20px;
            cursor: pointer;
        }

        .cancel-button-row {
            display: table-cell;
            max-width: 600px;

            button {
                padding: 0px 40px 0px 40px !important;
                color: $color-black;
                background-color: $color-white-200;
            }
        }


    }


}


// reset password
.change-pass {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0px 7px 0px 7px;

    .form-header {
        padding: 0px 0px 15px 0px;

        h2 {
            font-weight: 600;
            color: var(--black, #000);
            font-size: 16px;
            margin: 0px 0px 5px 0px;

        }

        h3 {
            font-weight: 600;
            color: var(--black, #000);
            font-size: 14px;
            margin: 0px 0px 8px 0px;

        }

        span {
            font-size: 11px;
            font-weight: 500;

            a {
                color: var(--Blue-1, #2F80ED);
                cursor: pointer;
            }
        }

        .steps {
            font-size: 12px;
            font-weight: 500;
            position: relative;
        }



        .sign-tex {
            padding-bottom: 13px;
        }
    }

    .form-body {
        padding: 0px 0px 0px 0px;
        font-size: 12px;

        .label {
            font-size: 13px;
            font-weight: 600;
            color: var(--black, #000);
        }



        .field-heading {
            font-size: 11px;
            font-weight: 600;
            padding-bottom: 10px;
        }

    }

    .form-footer {
        display: table-cell;
        max-width: 600px;
        font-size: 15px;

        button {
            color: var(--black, #ffffff);
            background-color: #000;
            padding: 0px 35px 0px 35px;

        }

    }


}

// Broker card
.broker-card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px 7px 15px 10px;

    .form-header {
        padding: 0px 0px 15px 0px;

        h2 {
            font-weight: 700;
            color: var(--black, #000);
            font-size: 17px;
            margin: 0px 0px 5px 0px;

        }

        h3 {
            font-weight: 600;
            color: var(--black, #000);
            font-size: 14px;
            margin: 0px 0px 5px 0px;

        }

        p {
            font-weight: 500;
            color: var(--black, #898888);
            font-size: 10px;
            margin: 3px 0px 0px 3px;

        }

        span {
            font-size: 11px;
            font-weight: 500;

            a {
                color: var(--Blue-1, #2F80ED);
                cursor: pointer;
            }
        }

        .steps {
            font-size: 12px;
            font-weight: 500;
            position: relative;
        }

        .image-size {
            img {
                width: 260px;
                height: 450px;

            }
        }

        .logo-size {
            img {
                width: 65px;
                padding-bottom: 5px;

            }
        }
    }

    .form-body {
        padding: 0px 0px 0px 0px;
        font-size: 12px;

        .label {
            font-size: 13px;
            font-weight: 600;
            color: var(--black, #000);
        }

        mat-radio-group {
            color: var(--black, #000);
            font-size: 10px;
            font-weight: 400;

            .example-radio-button {
                color: var(--black, #000) !important;
                font-size: 12px;
                font-weight: 400;
            }

        }

        .seasons {
            padding-bottom: 10px;
        }

        .field-heading {
            font-size: 11px;
            font-weight: 600;
            padding-bottom: 10px;
        }

        .field-heading02 {
            font-size: 11px;
            font-weight: 600;
            padding: 0px 0px 0px 0px;
        }

    }

    .unit-body {
        padding: 0px 0px 0px 0px;
        font-size: 11px;

        .field-heading {
            font-size: 12px;
            font-weight: 700;
            padding: 10px 0px 10px 0px;
        }

        .label {
            font-size: 13px;
            font-weight: 600;
            color: var(--black, #000);
        }

        .unit-details {
            margin-bottom: 10px;
            padding: 10px 10px 10px 10px !important;
            border-radius: 10px !important;
            border: 1px solid rgba(111, 111, 111, 0.20);
            display: inline-block;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 8px 0px;

            .label {
                font-size: 12px;
                font-weight: 500;
                color: var(--black, #000);
                padding-top: 15px;
            }

            .seasons {
                padding: 20px 0px 0px 0px;

                mat-radio-group {
                    color: var(--black, #000);
                    font-size: 10px;
                    font-weight: 400;

                    .example-radio-button {
                        color: var(--black, #000) !important;
                        font-size: 12px;
                        font-weight: 400;
                    }

                }
            }

            .no-unit {
                font-size: 12px;
                font-weight: 500;
                padding-bottom: 10px;
            }

            .units-butn {
                margin: 0px 5px 5px 0px;

                button {
                    height: 25px !important;
                    width: 25px !important;

                }
            }

            .upload-butn {
                margin: 5px 5px 5px 0px;
                max-width: 20px;

                button {
                    height: 25px !important;
                    width: 100px !important;
                    min-width: unset !important;


                }

                // .icon-align{
                //     padding-bottom: 20px !important;
                // }
            }


        }



    }

    .form-footer {
        display: table-cell;
        max-width: 600px;

        button {
            color: var(--black, #ffffff);
            background-color: #000;
            padding: 0px 35px 0px 35px;
        }

        .mat-icon {
            font-size: 30px;
            margin-right: -120px;
            margin-top: 20px;
            cursor: pointer;
        }

        .cancel-button-row {
            display: table-cell;
            max-width: 600px;

            button {
                padding: 0px 40px 0px 40px !important;
                color: $color-black;
                background-color: $color-white-200;
            }
        }


    }


}


.login-form {
    margin: 20px 0px 0px 0px;
    font-size: 12px;

    .forgot {
        margin-top: -10px;
        padding: 0px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            color: var(--Blue-1, #2F80ED);
        }
    }
}

.reset-password {
    margin: 0px 0px 0px 0px;
    font-size: 12px;

    .forgot {
        margin-top: -10px;
        padding: 0px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            color: var(--Blue-1, #2F80ED);
        }
    }
}

.file-card {
    max-height: 50px !important;
    padding: 1px 1px 20px 1px !important;
    background-color: $color-light-gray;

    .upload-file {
        cursor: pointer;

        #file1 {
            display: none;
        }

        #file2 {
            display: none;
        }

        #file3 {
            display: none;
        }

        #file4 {
            display: none;
        }

        #file5 {
            display: none;
        }

        #file6 {
            display: none;
        }

        #file7 {
            display: none;
        }

        #file8 {
            display: none;
        }

        #file9 {
            display: none;
        }

        #file10 {
            display: none;
        }

        #file11 {
            display: none;
        }

        #file12 {
            display: none;
        }
    }

    .pdf-icon {
        img {
            height: 50px;
            width: 50px;
        }

        .pdf-close-icon {
            font-size: 17px;
            position: absolute;
            margin: 0px 0px 60px 460px;
            cursor: pointer;
        }

        .pdf-closed {
            font-size: 17px;
            position: absolute;
            margin: 25px 0px 60px 240px;
            cursor: pointer;
        }

        .pdf-closed25 {
            font-size: 17px;
            position: absolute;
            margin: 35px 0px 60px 170px;
            cursor: pointer;
        }

        .pdf-closed50 {
            font-size: 17px;
            position: absolute;
            margin: 25px 0px 60px 390px;
            cursor: pointer;
        }

        // @media (max-width: 500px) {
        //     .pdf-closed {
        //         font-size: 17px;
        //         position: absolute;
        //         margin: 0px 0px 60px 280px;
        //         cursor: pointer;
        //     }
        // }
    }

    // Loader
    .custom-loader2 {
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 9;
        background-color: rgba($color: $color-white, $alpha: 0.8);

        .loader-text {
            position: sticky;
            top: 80px;
            display: flex;
            width: 100%;
            padding-top: 8%;
            justify-content: center;
            align-items: center;

            h4 {
                font-weight: $weight-500;
                margin: 0px 0px 0px 10px;
            }

            .mat-spinner {
                circle {
                    stroke: $color-accent;
                }
            }
        }

        .loader-text-progress {
            // position: sticky;
            // top: 0px;
            display: flex;
            flex-direction: column;
            // width: 100%;
            // padding: 30%;
            // justify-content: center;
            // align-items: center;
            position: sticky;
            top: 80px;
            display: flex;
            width: 100%;
            padding-top: 5%;
            justify-content: center;
            align-items: center;

            h4 {
                font-weight: 500;
                margin: 0px 0px 0px 10px;
            }

            .mat-spinner {
                circle {
                    stroke: #1c75bc;
                }
            }
        }

    }
}

.padding {
    padding: 2px 0px 20px 3px;

    span {
        font-size: 8px;

        a {
            cursor: pointer;
        }
    }
}

//  social icon button
.social-button {
    display: table-cell;

    button {
        height: 30px !important;
        line-height: 0 !important;

        .icon {
            width: 13px !important;
            height: 13px !important;
            line-height: 10px !important;
            padding-bottom: 5px !important;
        }
    }
}

//  social icon button
.unit-button {
    display: table-cell;

    button {
        height: 28px !important;
        line-height: 0 !important;

        .icon {
            width: 13px !important;
            height: 13px !important;
            line-height: 10px !important;
            padding-bottom: 5px !important;
        }
    }
}

//  social icon button
.project-button {
    display: table-cell;
    margin-top: 10px;

    button {
        height: 30px !important;
        line-height: 0 !important;

        .icon {
            width: 12px !important;
            height: 12px !important;
            line-height: 10px !important;
            padding-bottom: 5px !important;
        }
    }
}

//  social icon button
.download-button {
    justify-content: center !important;
    text-align: center !important;
    margin-top: 5px;

    button {
        font-size: 12px !important;
        width: 100%;
        height: 25px !important;
        line-height: 0 !important;

    }
}

// card details
.card-details {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;

    .actor-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $color-light-gray;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        border: 1px solid $color-border;
        position: relative;

        img {
            width: 78px;
            height: 78px;
            border-radius: 50%;
            object-fit: cover;
            vertical-align: middle;
            cursor: pointer;
            outline: none;
        }

        .mat-icon {
            font-size: 40px;
            width: unset;
            height: unset;
            line-height: unset;
            vertical-align: middle;
            color: $color-black-400;
        }

        .mat-icon-button {
            width: 30px;
            height: 30px;
            line-height: unset;
            position: absolute;
            right: -2px;
            bottom: 4px;
            background-color: $color-primary;

            .mat-icon {
                font-size: 18px;
                width: unset;
                height: unset;
                line-height: unset;
                vertical-align: middle;
                color: $color-white;
            }
        }
    }

    .actor-details {
        text-align: center;
        margin: 20px 0px 0px 0px;

        h4 {
            font-weight: $weight-600;
            color: $color-black-100;
            font-size: $size-13;
            margin: 0px;
        }

        span {
            font-size: $size-10;
            font-weight: $weight-600;
            color: $color-black-100;
        }

        small {
            font-size: $size-11;
            font-weight: $weight-600;
            color: $color-black-200;
        }
    }
}

.z-index {
    z-index: 999;
}

.img-icon {
    img {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        object-fit: cover;
        vertical-align: middle;
        cursor: pointer;
        outline: none;
    }
}



.form-contentainer {
    padding: 56px 0px 10px 0px;
    position: relative;
    min-height: 430px;
}

// Loader
.custom-loader {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 9;
    background-color: rgba($color: $color-white, $alpha: 0.8);

    .loader-text {
        position: sticky;
        top: 80px;
        display: flex;
        width: 100%;
        padding-top: 20%;
        justify-content: center;
        align-items: center;

        h4 {
            font-weight: $weight-500;
            margin: 0px 0px 0px 10px;
        }

        .mat-spinner {
            circle {
                stroke: $color-accent;
            }
        }
    }

    .loader-text-progress {
        // position: sticky;
        // top: 0px;
        display: flex;
        flex-direction: column;
        // width: 100%;
        // padding: 30%;
        // justify-content: center;
        // align-items: center;
        position: sticky;
        top: 80px;
        display: flex;
        width: 100%;
        padding-top: 5%;
        justify-content: center;
        align-items: center;

        h4 {
            font-weight: 500;
            margin: 0px 0px 0px 10px;
        }

        .mat-spinner {
            circle {
                stroke: #1c75bc;
            }
        }
    }

    .loader-text-progress001 {
        display: flex;
        flex-direction: column;
        position: sticky;
        top: 80px;
        display: flex;
        width: 50%;
        padding-top: 20%;
        justify-content: center;
        align-items: center;
        margin-left: 15pc;

        h4 {
            font-weight: 500;
            margin: 0px 0px 0px 10px;
        }

        .mat-spinner {
            circle {
                stroke: #1c75bc;
            }
        }
    }

    .loader-text-progress002 {
        display: flex;
        flex-direction: column;
        position: sticky;
        top: 80px;
        display: flex;
        width: 50%;
        padding-top: 20%;
        justify-content: center;
        align-items: center;
        margin-left: 22pc;

        h4 {
            font-weight: 500;
            margin: 0px 0px 0px 10px;
        }

        .mat-spinner {
            circle {
                stroke: #1c75bc;
            }
        }
    }


}

// Pager
.page-title {
    padding: 10px 16px 10px 0px;

    .title {
        display: flex;
        // justify-content: flex-start;
        align-items: center;

        h2 {
            font-size: $size-19;
            color: $color-black-100;
            font-weight: $weight-900;
            margin: 0px 3px 0px 0px;
        }

        .mat-icon {
            cursor: pointer;
        }

        .back-button {
            width: 30px !important;
            height: 30px !important;
            line-height: unset !important;
            vertical-align: middle !important;
            margin-right: 6px;
            border: none;
            background-color: #f8f8f8;

            .mat-icon {
                font-size: 20px;
                color: $color-black;
                cursor: pointer;
            }
        }
    }

    .search {
        background-color: white;
        height: 30px;
        border: none;
        border-radius: 50px;
        margin: 15px 0px 0px 10px;
        width: calc(100% - 24px);
        box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 4px 0px;

        input {
            flex: 1 1 0%;
            margin-inline-start: 25px;
            outline: none;
            border: none;
            width: 150%;
            padding-top: 8px;
        }

        .search-icon {
            position: relative;
            bottom: 19px;
            margin: 0px 0px 0px 5px;
            color: #92a6b9;
            cursor: pointer;
        }

    }

    // Small select
    .small-select {
        font-size: $size-10 !important;
        padding-top: 10px !important;

        mat-label {
            font-weight: $weight-500 !important;
            font-size: $size-14 !important;
        }

        .mat-form-field-infix {
            padding: 10px 0px 10px 0px;
        }

        .mat-form-field-label {
            font-size: $size-12;
        }

        .mat-form-field-wrapper {
            padding: 0px;
        }

        .mat-select {
            font-weight: $weight-600 !important;
            font-size: $size-12 !important;
            color: $color-black-100;
        }
    }

    .filter-button {
        display: table-cell;
        padding-top: 14px;
        padding-right: 10px;
    }
}



// Common table
.common-table {
    .mat-table {
        width: 100%;
        border-bottom: 0px;
        border-radius: 10px 10px 10px 10px;
        @include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.1));
        overflow: hidden;

        .mat-header-row {
            min-height: 0px;
            background-color: $color-background;
            border-radius: 10px 10px 0px 0px;

            .mat-header-cell {
                padding: 10px 5px 10px 6px;
                font-weight: $weight-600;
                font-size: $size-12;
                background-color: $color-black-100;
                color: $color-white-200;
            }
        }

        .mat-row {
            min-height: 0px;
            background-color: $color-white;

            // align-items: flex-start;
            .mat-cell {
                padding: 10px 6px 10px 6px;

                h4 {
                    font-size: $size-12;
                    color: $color-black-200;
                    font-weight: $weight-600;
                    margin: 0px;
                    width: 100px;
                }

                h5 {
                    color: $color-black-200;
                    font-size: $size-11;
                    font-weight: $weight-600;
                    margin: 0px;
                    width: 100px;
                }

                span {
                    font-size: $size-11;
                    font-weight: $weight-600;
                    //color: $color-black-200;
                }

                a {
                    font-size: $size-12;
                    color: $color-accent;
                    outline: none;
                    text-decoration: underline;
                }

                .details-card {
                    flex: 1;

                    h3 {
                        font-weight: $weight-600;
                        color: $color-black-200;
                        margin: 0px;
                    }

                    h4 {
                        font-size: $size-12;
                        color: $color-black-200;
                        font-weight: $weight-600;
                        margin: 0px;
                        width: 50%;
                    }

                    span {
                        font-size: $size-11;
                        font-weight: $weight-600;
                        color: $color-black-200;
                    }

                    small {
                        font-size: $size-10;
                        font-weight: $weight-600;
                        color: $color-black-300;
                    }

                    a {
                        color: $color-black-100;
                        outline: none;
                        text-decoration: none;

                        .mat-icon {
                            font-size: $size-13;
                            width: unset;
                            height: unset;
                            vertical-align: middle;
                            color: $color-black;
                            margin-right: 8px;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    .chip-tag {
                        margin-left: 0px;
                        margin-right: 6px;
                    }
                }

                .split-sections {
                    display: flex;
                    justify-content: start;
                    align-items: start;

                    .item-image {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        overflow: hidden;
                        background-color: $color-light-gray;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        border: 1px solid $color-border;

                        img {
                            width: 100%;
                            object-fit: cover;
                        }

                        .mat-icon {
                            font-size: 30px;
                            width: unset;
                            height: unset;
                            line-height: unset;
                            vertical-align: middle;
                            color: $color-black-400;
                        }
                    }

                    h3 {
                        font-weight: $weight-600;
                        color: $color-black-200;
                        margin: 0px;
                    }

                    h4 {
                        font-weight: $weight-600;
                        color: $color-black-200;
                        margin: 0px;
                    }

                    span {
                        font-size: $size-12;
                        font-weight: $weight-600;
                        color: $color-black-200;
                    }

                    small {
                        font-size: $size-11;
                        font-weight: $weight-600;
                        color: $color-black-300;
                    }
                }
            }

            .action-cell {
                padding: 10px 0px 10px 12px !important;

                .action-button {
                    width: 30px !important;
                    height: 30px !important;
                    line-height: unset !important;
                    vertical-align: middle !important;
                    background-color: $color-light-gray;
                    border-radius: 8px;
                    margin-left: 10px;

                    .mat-icon {
                        font-size: 15px;
                        color: $color-black-200;
                    }

                }

                .action-checkbox {
                    width: 30px !important;
                    height: 25px !important;
                    line-height: unset !important;
                    vertical-align: middle !important;
                    // background-color: $color-light-gray;
                    border-radius: 8px;
                    margin-left: 10px;

                }
            }

            &:hover {
                background-color: $color-accent-light;
            }

            .label-cell {
                padding: 0px 16px 0px 16px;
            }
        }

        .mat-footer-row {
            .mat-footer-cell {
                font-weight: $weight-600;
                padding: 16px 16px 16px 16px;
                font-size: $size-13;
                color: $color-black-100;
            }
        }
    }

    .mat-paginator {
        background-color: transparent;
        width: 100%;
        margin-top: 10px;

        .mat-paginator-outer-container {
            .mat-paginator-container {
                .mat-paginator-page-size {
                    .mat-paginator-page-size-label {
                        font-weight: $weight-600;
                        font-size: $size-13;
                        color: $color-black-100;
                    }

                    .mat-paginator-page-size-select {
                        font-weight: $weight-600;
                        font-size: $size-13;
                        color: $color-black-100;
                    }
                }

                .mat-paginator-range-actions {
                    .mat-paginator-range-label {
                        font-weight: $weight-600;
                        font-size: $size-13;
                        color: $color-black-100;
                    }

                    .mat-icon-button {
                        background-color: $color-white;
                        border-radius: 10px;
                        @include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.1));
                        margin-left: 10px;

                        .mat-paginator-icon {
                            color: $color-black-400;
                        }
                    }

                    .mat-icon-button:not([disabled]) {
                        .mat-paginator-icon {
                            color: $color-accent;
                        }

                        &:hover {
                            background-color: $color-accent-light !important;
                        }
                    }
                }
            }
        }
    }
}

.mat-elevation-unit {
    box-shadow: none !important;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    display: block;
    width: 100%;
    overflow-x: auto
}

// unit table
.unit-table {
    .mat-table {
        border-bottom: 0px;
        border-radius: 10px 10px 10px 10px;
        @include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.1));
        width: 100%;
        margin-bottom: 1rem;
        display: table;
        border-collapse: collapse;
        margin: 0px;

        .mat-header-row {
            min-height: 0px;
            background-color: $color-background;
            border-radius: 10px 10px 0px 0px;
            display: table-row;

            .mat-header-cell {
                padding: 10px 12px 10px 12px;
                font-weight: $weight-600;
                font-size: $size-12;
                background-color: $color-black-100;
                color: $color-white-200;
                word-wrap: initial;
                display: table-cell;
                line-break: unset;
                width: auto;
                white-space: nowrap;
                overflow: hidden;
                vertical-align: middle;
            }
        }

        .mat-row {
            min-height: 0px;
            background-color: $color-white;
            display: table-row;

            .mat-cell {
                padding: 10px 10px 10px 10px;
                word-wrap: initial;
                display: table-cell;
                line-break: unset;
                width: auto;
                white-space: nowrap;
                overflow: hidden;
                vertical-align: middle;

                h4 {
                    font-size: $size-13;
                    color: $color-black-200;
                    font-weight: $weight-600;
                    margin: 0px;
                    width: 120px;
                }

                h5 {
                    color: $color-black-200;
                    font-size: $size-12;
                    font-weight: $weight-600;
                    margin: 0px;
                    // width: 120px;
                }

                span {
                    font-size: $size-12;
                    font-weight: $weight-600;
                    //color: $color-black-200;
                }

                a {
                    font-size: $size-13;
                    color: $color-accent;
                    outline: none;
                    text-decoration: underline;
                }

                .details-card {
                    flex: 1;

                    h3 {
                        font-weight: $weight-600;
                        color: $color-black-200;
                        margin: 0px;
                    }

                    h4 {
                        font-size: $size-13;
                        color: $color-black-200;
                        font-weight: $weight-600;
                        margin: 0px;
                        width: 60%;
                    }

                    span {
                        font-size: $size-12;
                        font-weight: $weight-600;
                        color: $color-black-200;
                    }

                    small {
                        font-size: $size-11;
                        font-weight: $weight-600;
                        color: $color-black-300;
                    }

                    a {
                        color: $color-black-100;
                        outline: none;
                        text-decoration: none;

                        .mat-icon {
                            font-size: $size-14;
                            width: unset;
                            height: unset;
                            vertical-align: middle;
                            color: $color-black;
                            margin-right: 8px;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    .chip-tag {
                        margin-left: 0px;
                        margin-right: 6px;
                    }
                }

                .split-sections {
                    display: flex;
                    justify-content: start;
                    align-items: start;

                    .item-image {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        overflow: hidden;
                        background-color: $color-light-gray;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        border: 1px solid $color-border;

                        img {
                            width: 100%;
                            object-fit: cover;
                        }

                        .mat-icon {
                            font-size: 30px;
                            width: unset;
                            height: unset;
                            line-height: unset;
                            vertical-align: middle;
                            color: $color-black-400;
                        }
                    }

                    h3 {
                        font-weight: $weight-600;
                        color: $color-black-200;
                        margin: 0px;
                    }

                    h4 {
                        font-weight: $weight-600;
                        color: $color-black-200;
                        margin: 0px;
                    }

                    span {
                        font-size: $size-13;
                        font-weight: $weight-600;
                        color: $color-black-200;
                    }

                    small {
                        font-size: $size-11;
                        font-weight: $weight-600;
                        color: $color-black-300;
                    }
                }
            }

            .action-cell {
                padding: 10px 0px 10px 12px !important;

                .action-button {
                    width: 30px !important;
                    height: 30px !important;
                    line-height: unset !important;
                    vertical-align: middle !important;
                    background-color: $color-light-gray;
                    border-radius: 8px;
                    margin-left: 10px;

                    .mat-icon {
                        font-size: 18px;
                        color: $color-black-200;
                    }
                }

                .action-checkbox {
                    width: 30px !important;
                    height: 25px !important;
                    line-height: unset !important;
                    vertical-align: middle !important;
                    // background-color: $color-light-gray;
                    border-radius: 8px;
                    margin-left: 10px;

                }
            }

            &:hover {
                background-color: $color-accent-light;
            }

            .label-cell {
                padding: 0px 16px 0px 16px;
            }
        }

        .mat-footer-row {
            .mat-footer-cell {
                font-weight: $weight-600;
                padding: 16px 16px 16px 16px;
                font-size: $size-13;
                color: $color-black-100;
            }
        }
    }


}

.unit-paginator {
    background-color: transparent;
    width: 100%;
    margin-top: 10px;

    .mat-paginator-outer-container {
        .mat-paginator-container {
            .mat-paginator-page-size {
                .mat-paginator-page-size-label {
                    font-weight: $weight-600;
                    font-size: $size-13;
                    color: $color-black-100;
                }

                .mat-paginator-page-size-select {
                    font-weight: $weight-600;
                    font-size: $size-13;
                    color: $color-black-100;
                }
            }

            .mat-paginator-range-actions {
                .mat-paginator-range-label {
                    font-weight: $weight-600;
                    font-size: $size-13;
                    color: $color-black-100;
                }

                .mat-icon-button {
                    background-color: $color-white;
                    border-radius: 10px;
                    @include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.1));
                    margin-left: 10px;

                    .mat-paginator-icon {
                        color: $color-black-400;
                    }
                }

                .mat-icon-button:not([disabled]) {
                    .mat-paginator-icon {
                        color: $color-accent;
                    }

                    &:hover {
                        background-color: $color-accent-light !important;
                    }
                }
            }
        }
    }
}

// Common table
.dashboard-table {
    .heading {
        padding: 10px 16px 7px 16px;
        font-weight: $weight-700;
        font-size: $size-16;
        background-color: $color-white-200;
        color: $color-black;
        border-radius: 10px 10px 0px 0px;
        height: 20px;

    }

    .mat-table {
        width: 100%;
        border-bottom: 0px;
        @include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.1));
        overflow: hidden;

        .mat-header-row {
            min-height: 0px;
            background-color: $color-background;
            border-radius: 10px 10px 0px 0px;

            .mat-header-cell {
                padding: 10px 5px 10px 6px;
                font-weight: $weight-600;
                font-size: $size-12;
                background-color: $color-white-200;
                color: $color-black;
            }
        }

        .mat-row {
            min-height: 0px;
            background-color: $color-white;

            .mat-cell {
                padding: 10px 6px 10px 6px;

                h4 {
                    font-size: $size-12;
                    color: $color-black-200;
                    font-weight: $weight-600;
                    margin: 0px;
                    width: 100px;
                }

                h5 {
                    color: $color-black-200;
                    font-size: $size-13;
                    font-weight: $weight-600;
                    margin: 0px;
                }

                span {
                    font-size: $size-13;
                    font-weight: $weight-600;
                }

                a {
                    font-size: $size-14;
                    color: $color-accent;
                    outline: none;
                    text-decoration: underline;
                }

                .details-card {
                    flex: 1;

                    h3 {
                        font-weight: $weight-600;
                        color: $color-black-200;
                        margin: 0px;
                    }

                    h4 {
                        font-size: $size-14;
                        color: $color-black-200;
                        font-weight: $weight-600;
                        margin: 0px;
                    }

                    span {
                        font-size: $size-13;
                        font-weight: $weight-600;
                        color: $color-black-200;
                    }

                    small {
                        font-size: $size-11;
                        font-weight: $weight-600;
                        color: $color-black-300;
                    }

                    a {
                        color: $color-black-100;
                        outline: none;
                        text-decoration: none;

                        .mat-icon {
                            font-size: $size-14;
                            width: unset;
                            height: unset;
                            vertical-align: middle;
                            color: $color-black;
                            margin-right: 8px;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    .chip-tag {
                        margin-left: 0px;
                        margin-right: 6px;
                    }
                }

                .split-sections {
                    display: flex;
                    justify-content: start;
                    align-items: start;

                    .item-image {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        overflow: hidden;
                        background-color: $color-light-gray;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        border: 1px solid $color-border;

                        img {
                            width: 100%;
                            object-fit: cover;
                        }

                        .mat-icon {
                            font-size: 30px;
                            width: unset;
                            height: unset;
                            line-height: unset;
                            vertical-align: middle;
                            color: $color-black-400;
                        }
                    }

                    h3 {
                        font-weight: $weight-600;
                        color: $color-black-200;
                        margin: 0px;
                    }

                    h4 {
                        font-weight: $weight-600;
                        color: $color-black-200;
                        margin: 0px;
                    }

                    span {
                        font-size: $size-13;
                        font-weight: $weight-600;
                        color: $color-black-200;
                    }

                    small {
                        font-size: $size-11;
                        font-weight: $weight-600;
                        color: $color-black-300;
                    }
                }
            }

            .action-cell {
                padding: 10px 16px 10px 12px !important;

                .action-button {
                    width: 30px !important;
                    height: 30px !important;
                    line-height: unset !important;
                    vertical-align: middle !important;
                    background-color: $color-light-gray;
                    border-radius: 8px;
                    margin-left: 10px;

                    .mat-icon {
                        font-size: 18px;
                        color: $color-black-200;
                    }
                }

                .action-checkbox {
                    width: 30px !important;
                    height: 25px !important;
                    line-height: unset !important;
                    vertical-align: middle !important;
                    // background-color: $color-light-gray;
                    border-radius: 8px;
                    margin-left: 10px;

                }
            }

            &:hover {
                background-color: $color-accent-light;
            }

            .label-cell {
                padding: 0px 16px 0px 16px;
            }
        }

        .mat-footer-row {
            .mat-footer-cell {
                font-weight: $weight-600;
                padding: 16px 16px 16px 16px;
                font-size: $size-13;
                color: $color-black-100;
            }
        }
    }

    .mat-paginator {
        background-color: transparent;
        width: 100%;
        margin-top: 10px;

        .mat-paginator-outer-container {
            .mat-paginator-container {
                .mat-paginator-page-size {
                    .mat-paginator-page-size-label {
                        font-weight: $weight-600;
                        font-size: $size-13;
                        color: $color-black-100;
                    }

                    .mat-paginator-page-size-select {
                        font-weight: $weight-600;
                        font-size: $size-13;
                        color: $color-black-100;
                    }
                }

                .mat-paginator-range-actions {
                    .mat-paginator-range-label {
                        font-weight: $weight-600;
                        font-size: $size-13;
                        color: $color-black-100;
                    }

                    .mat-icon-button {
                        background-color: $color-white;
                        border-radius: 10px;
                        @include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.1));
                        margin-left: 10px;

                        .mat-paginator-icon {
                            color: $color-black-400;
                        }
                    }

                    .mat-icon-button:not([disabled]) {
                        .mat-paginator-icon {
                            color: $color-accent;
                        }

                        &:hover {
                            background-color: $color-accent-light !important;
                        }
                    }
                }
            }
        }
    }
}

.unpaid {
    color: #f7941d !important;
}

.paid-price {
    color: #219653 !important;
}


.mat-elevation-z8 {
    box-shadow: none !important;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
}

// Horizontal menubar
.horizontal-menu {
    ul {
        padding: 0px;
        margin: 0px;
        list-style-type: none;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $color-border;

        .list-item {
            margin: 0px 26px 0px 0px;

            .item {
                cursor: pointer;
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 0px 10px 0px;
                position: relative;

                .mat-icon {
                    width: unset;
                    height: unset;
                    line-height: unset;
                    font-size: 18px;
                    color: $color-black-300;
                    vertical-align: middle;
                }

                span {
                    font-size: $size-14;
                    font-weight: $weight-500;
                    color: $color-black-200;
                    margin: 0px;
                }
            }

            .active {
                margin-bottom: -2px;
                border-bottom: 3px solid $color-accent;

                span {
                    color: $color-accent;
                }
            }
        }
    }


}

// Dropdown menubar
.action-menubar {
    padding: 6px 0px 6px 0px !important;
    border-radius: 10px !important;
    @include box-shadow(5px 2px 10px 5px rgba(0, 0, 0, 0.1));

    .mat-menu-content {
        padding: 0px !important;
    }

    .mat-menu-item {
        line-height: unset;
        height: unset;
        padding: 8px 16px 8px 16px !important;

        .mat-icon {
            font-size: 18px;
            vertical-align: middle;
            line-height: unset;
            width: unset;
            height: unset;
            margin-right: 10px;
            color: $color-black-200;
        }

        span {
            font-size: $size-13 !important;
            font-weight: $weight-600 !important;
            color: $color-black-200;
            vertical-align: middle;
        }

        &:hover {
            background-color: $color-black;

            span {
                color: $color-white-200;
            }

            mat-icon {
                color: $color-white-200;
            }
        }
    }
}

.card-list {
    // max-height: 230px;
    padding: 10px 6px 5px 6px !important;
    border-radius: 10px !important;
    border: 1px solid rgba(111, 111, 111, 0.20);
    width: 100%;

    .card-heading {
        padding-bottom: 10px;
    }

    .card-img {
        img {
            background-size: cover;
            height: 260px;
            width: 220px;
            border-radius: 5px;
            object-fit: cover;
        }

    }

    .card-body {
        display: inline-block;
        padding: 0;
        width: 100%;

        .card-price {
            padding: 15px 5px 0px 0px !important;

            span {
                font-size: $size-15 !important;
                font-weight: $weight-600 !important;
                color: $color-black;

                white-space: pre;

                small {
                    font-size: $size-12 !important;
                    font-weight: $weight-500 !important;
                    color: $color-black-200;

                }

            }

            .price-box {
                margin-bottom: 10px;
                padding: 5px 10px 5px 10px !important;
                border-radius: 5px !important;
                border: 1px solid rgba(111, 111, 111, 0.20);
                display: inline-block;

                span {

                    font-size: $size-12 !important;
                    font-weight: $weight-600 !important;
                    color: $color-black;

                    white-space: pre;

                    small {
                        font-size: $size-12 !important;
                        font-weight: $weight-500 !important;
                        color: $color-black-200;

                    }

                }


            }

        }

        .card-chip {
            display: block;
            padding: 0;
            width: 100%;
            margin-top: 10px;
            overflow-x: hidden;

            .price-box {
                margin-bottom: 5px;
                padding: 2px 2px 2px 2px !important;
                border-radius: 5px !important;
                border: 1px solid rgba(111, 111, 111, 0.20);
                display: inline-block;
                background-color: #d4d4d4;
                display: flex;
                width: auto;

                small {
                    font-size: 9px !important;
                    font-weight: $weight-500 !important;
                    color: $color-black-200;

                }
            }
        }

        .view-detail-btn {
            text-align: center;
            align-items: center;

            button {
                font-size: 12px !important;
                height: 25px !important;
                line-height: 0 !important;
                text-align: center;
                align-items: center;

            }

        }

    }

}

.notification-card {
    max-height: 110px;
    border: 1px solid rgba(111, 111, 111, 0.20);



    .card-img {
        img {
            background-size: cover;
            height: 70px;
            width: 70px;
            border-radius: 5px;
            object-fit: cover;
        }

    }

    .card-body {
        padding: 0;

        .card-price {
            padding: 5px 20px 5px 0px !important;

            span {
                font-size: $size-15 !important;
                font-weight: $weight-600 !important;
                color: $color-black;

                white-space: pre;

                small {
                    font-size: $size-12 !important;
                    font-weight: $weight-500 !important;
                    color: $color-black-200;

                }

            }

        }


    }


}

.profile-card {
    background: #F6F3F2;
    border-radius: 10px;
    @include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.1));
    padding: 10px 10px;
    cursor: pointer;

    .card-img {
        img {
            background-size: cover;
            height: 140px;
            width: 100%;
            border-radius: 5px;
            object-fit: cover;
            z-index: -1;
        }

        .price-chip {
            display: flex;
            background: rgba(35, 79, 104, 0.69);
            border-radius: 10px;
            position: absolute;
            margin: 100px 0px 0px 115px;
            padding: 5px 5px;

            .text-clr {
                font-size: $size-13 !important;
                font-weight: $weight-600 !important;
                color: $color-white-200;
                opacity: 10;
                z-index: 999;

            }

        }



        .like-btn {
            position: absolute;
            margin: 8px 0px 0px 170px;
            font-size: 10px;

            button {
                width: 34px !important;
                height: 34px !important;
                background-color: rgba(234, 233, 233, 0.78);

                mat-icon {
                    font-size: 15px;
                }
            }
        }

    }

    .card-body {
        padding: 0;

        mat-icon {
            transform: scale(0.7);
            color: $color-primary;
        }

        h4 {
            font-size: $size-13 !important;
            font-weight: $weight-600 !important;
            color: $color-black;


        }

        span {
            font-size: $size-13 !important;
            font-weight: $weight-600 !important;
            color: $color-black;
            margin-top: 7px;

        }

        small {
            font-size: $size-12 !important;
            font-weight: $weight-500 !important;
            color: $color-black-200;
            margin-top: 8px !important;

        }



    }

}

.profile-detail {
    max-height: 160px !important;
    border-radius: 17px;
    background: #F6F3F2;

    .subcard {
        * {
            margin: 0;
            padding: 0;
        }

        background: #ffffff;
        max-height: 65px !important;
        border-radius: 10px !important;
        justify-content: center;

        span {
            font-size: $size-10 !important;
            padding: 10px 0px 0px 10px;
        }

        h5 {
            margin: 15px 0px 0px 10px;

        }

    }

    .card-operating {
        span {
            display: inline-block;
            border-bottom: 1px solid $color-primary-light;
            padding-bottom: 2px;
            font-size: $size-13 !important;
            color: $color-primary-light;
        }

        small {
            font-size: $size-16 !important;
            font-weight: $weight-600 !important;
            color: $color-black;
            padding-top: 10px;
        }
    }

}

.detail-image {
    img {
        background-size: cover;
        height: 160px;
        width: 100%;
        border-radius: 5px;
        object-fit: cover;
    }
}


.dashboard-card {
    background-color: $color-light-gray;
    border-radius: 10px;
    @include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.1));
    padding: 12px 16px;

    .welcome-img {
        height: 120px;
        width: 70%;

        .icon {
            background-size: cover;
            height: 131px;
            flex-shrink: 0;

        }


    }

}

.analytics-card {
    background-color: $color-white-200;
    border-radius: 10px;
    @include box-shadow(0px 1px 1px 0px rgba(0, 0, 0, 0.1));
    padding: 12px 10px;
    border: 1px solid rgba(111, 111, 111, 0.20);

    span {
        font-size: $size-14 !important;
        font-weight: $weight-700 !important;
        color: $color-black;
        margin-top: 5px;
    }

    .month-select {
        background-color: $color-light-gray;
        font-size: 10px;
        border-radius: 6px;
        padding: 8px 8px;
    }

    .chart-tooltip {
        padding: 25px 0px 0px 10px;

        small {
            margin: 0px 0px 5px 25px;
            font-size: 12px !important;
        }

        span {
            margin: 0px 0px 20px 30px;
            font-size: 15px !important;
            font-weight: $weight-700 !important;

        }


    }

}




.statistics-card {
    background-color: $color-black;
    border-radius: 10px;
    @include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.1));
    padding: 12px 16px;

    span {
        font-size: $size-14 !important;
        font-weight: $weight-700 !important;
        color: $color-black;
        margin-top: 5px;

    }

    .months-select {
        background-color: $color-light-gray;
        font-size: 8px;
        border-radius: 7px;
        padding: 6px 6px;
    }

}

// Dropdown
.dropdown-menu {
    margin-top: 10px;
    @include box-shadow(0px 2px 10px 0px rgba(0, 0, 0, 0.1));

    .header-section {
        padding: 16px;
        border-bottom: 1px dashed $color-border;

        .mat-body-2 {
            margin-top: 5px;
            font-weight: $weight-600;
            color: $color-black-100;
        }

        .mat-body-1 {
            font-weight: $weight-600;
            color: $color-black-200;
        }
    }

    .menu-section {
        padding: 0px 0px 0px 0px;
        border-bottom: 1px dashed $color-border;
    }

    .footer-section {
        padding: 16px;
        text-align: center;
    }

    .mat-menu-content {
        padding: 0px !important;
    }

    .mat-menu-item {
        .mat-icon {
            width: unset;
            height: unset;
            padding: 8px;
            font-size: 20px;
            color: $color-black-200 !important;
            background-color: $color-light-gray;
            border-radius: 4px;
        }

        span {
            font-size: $size-14;
            font-weight: $weight-600;
            color: $color-black-100;
        }

        &:hover {
            background-color: transparent !important;
        }
    }
}

.create-link {
    cursor: pointer;
    color: var(--Blue-1, var(--Colors-Text-Primary, #2F80ED));
}

// Status Active,  or inactive
.status-inactive {
    width: 60px;
    text-align: center;
    border-radius: 5px;
    padding: 6px 10px 6px 10px;
    background-color: #fdead2;
    color: #F2994A !important;
}

.status-active {
    width: 60px;
    text-align: center;
    border-radius: 5px;
    padding: 6px 10px 6px 10px;
    background-color: #DDE7E3;
    color: #36B37E !important;
}

.status-process {
    width: 60px;
    text-align: center;
    border-radius: 5px;
    padding: 6px 10px 6px 10px;
    background-color: #6e72e7;
    color: #ffffff !important;
}

// view project / property detail
.view-details {

    // padding: 10px 6px 5px 6px !important;
    // border-radius: 10px !important;
    // border: 1px solid rgba(111, 111, 111, 0.20);
    .card-heading {
        padding-bottom: 10px;
    }

    .card-img {
        position: relative;
        text-align: center;
        display: flex;
        justify-content: center;

        img {
            background-size: cover;
            height: 110px;
            width: 120px;
            border-radius: 5px;
            object-fit: cover;
            margin-bottom: 5px;
        }

        .centered {
            position: absolute;
            justify-content: center;
            text-align: center;
            margin: 50px 0px 0px -50px;
            color: #ffffff;
            font-size: 30px;
            font-weight: $weight-700 !important;
            transform: translate(-50%, -50%);
            display: inline-block;
        }

    }

    .card-body {
        padding: 0;
        margin-top: 20px;

        .card-price {
            margin: 20px 0px 20px 0px;

            .price-box {
                margin-bottom: 10px;
                padding: 7px 20px 7px 10px !important;
                border-radius: 5px !important;
                border: 1px solid rgba(111, 111, 111, 0.20);

                span {

                    font-size: $size-15 !important;
                    font-weight: $weight-600 !important;
                    color: $color-black;

                    white-space: pre;

                    small {
                        font-size: $size-12 !important;
                        font-weight: $weight-500 !important;
                        color: $color-black-200;

                    }

                }
            }

        }

        .card-chip {
            text-align: center;
            border-radius: 5px;
            padding: 6px 10px 6px 10px;
            margin-bottom: 10px;
            margin-bottom: 20px;
            background-color: var(--bg1, #F3EEF1);
            color: #000000 !important;

        }

        .brochure {
            padding: 30px 0px 15px 0px;
            display: inline-block;
            position: relative;

            span {
                font-size: $size-15 !important;
                font-weight: $weight-600 !important;
                color: $color-black;
                white-space: pre;
            }

            .download-btn {
                cursor: pointer;
                text-align: center;
                justify-content: center;
                border-radius: 5px;
                padding: 6px 10px 6px 10px;
                border: 1px solid rgba(111, 111, 111, 0.20);

                small {
                    font-size: 18px;
                    color: #828282;
                    margin-right: 3px;
                }
            }

            .video-btn {
                cursor: pointer;
                display: inline-block;
                position: relative;
                text-align: center;
                justify-content: center;
                border-radius: 5px;
                padding: 6px 10px 6px 10px;
                border: 1px solid rgba(111, 111, 111, 0.20);

                &:hover {
                    background-color: #000;

                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    text-align: center;
                    justify-content: center;
                    border-radius: 5px;
                    padding: 6px 10px 6px 10px;
                    border: 1px solid rgba(111, 111, 111, 0.20);

                    span {
                        color: #fff !important;
                    }

                    small {
                        font-size: 18px;
                        color: #fff !important;
                        margin-right: 3px;
                    }

                }

                small {
                    font-size: 18px;
                    color: #828282;
                    margin-right: 3px;
                }


            }
        }

        .location-advantages {
            padding: 30px 0px 15px 0px;

            span {
                font-size: $size-15 !important;
                font-weight: $weight-600 !important;
                color: $color-black;
            }

            .link-create {
                span {
                    margin-left: 2px;
                    cursor: pointer;
                    color: var(--Blue-1, var(--Colors-Text-Primary, #2F80ED));
                }
            }

        }

        .property-reviews {
            padding: 50px 0px 100px 0px;

            span {
                font-size: $size-15 !important;
                font-weight: $weight-600 !important;
                color: $color-black;
            }
        }

        .hospital-chip {
            display: inline-block;
            position: relative;
            border-radius: 5px;
            padding: 10px 40px 10px 20px;
            margin-bottom: 10px;
            background-color: var(--bg1, #F8EDEA);
            color: #000000 !important;

            .chip-icon {
                img {
                    height: 40px;
                    width: 40px;
                }
            }

            .chip-content {
                text-align: center;
                justify-content: center;
                margin-top: 5px !important;

                span {
                    font-size: $size-13 !important;
                    font-weight: $weight-600 !important;
                    color: $color-black;
                }

                small {
                    font-size: $size-11 !important;
                    color: $color-primary-light;
                }
            }

        }

        .spare-chip {
            border-radius: 5px;
            padding: 10px 30px 15px 50px;
            background-color: var(--bg1, #F8EDEA);
            color: #000000 !important;

            .chip-content {
                width: 100px;
                cursor: pointer;
                margin-top: 5px !important;

                span {
                    font-size: $size-13 !important;
                    font-weight: $weight-600 !important;
                    color: $color-black;
                }

                small {
                    font-size: $size-11 !important;
                    color: $color-primary-light;
                }
            }

        }

    }

}


.colose-modal {
    mat-icon {
        cursor: pointer;
        margin-top: -10px;
        margin-left: 340px;

    }

}

.dashed {
    border-bottom: 2px dashed $color-border;
    margin-bottom: 5px;
}

// Pannel card
.pannel-card {
    background-color: $color-white;
    border-radius: 10px;
    border: 1px solid rgba(111, 111, 111, 0.20);
    padding: 16px 16px 16px 16px;

    .card-header {
        display: flex;
        justify-content: space-between;
        margin: 0px 0px 16px 0px;
        border-bottom: 2px dashed $color-border;

        h3 {
            font-size: $size-15;
            color: $color-black-100;
            font-weight: $weight-700;
            margin: 0px 0px 6px 0px;
        }

        h4 {
            color: $color-black-100;
            font-weight: $weight-600;
            margin: 0px 0px 6px 0px;
        }

        h5 {
            font-size: $size-13;
            color: $color-black-100;
            font-weight: $weight-600;
            margin: 0px 0px 6px 0px;
        }
    }


    .card-body {
        padding: 0px;

        .row-border {
            border-bottom: 1px solid $color-border;
            padding-bottom: 10px;
            margin-top: 16px;
        }

        .img-icon {
            width: 50px;
            height: 50px;
        }

        .card-row {
            padding-bottom: 6px;
            margin-top: 10px;
        }

        h5 {
            font-size: $size-13;
            color: $color-black-100;
            font-weight: $weight-600;
            margin: 0px 0px 16px 0px;
        }

    }

    .card-footer {
        padding: 16px 0px 16px 0px;
    }

    .body-password {
        padding: 0px 0px 16px 0px;
    }
}

// Common fields
.metadata-field {
    label {
        font-weight: $weight-500;
        font-size: $size-13;
        color: $color-black-200;
        margin: 0px 0px 0px 0px;
    }

    span {
        font-size: $size-13;
        font-weight: $weight-500;
        color: $color-black-200;
        margin: 0px 0px 0px 0px;
    }

    small {
        font-size: $size-11;
        font-weight: $weight-500;
        color: $color-black-200;
        margin: 0px 0px 0px 0px;
    }

    b {
        font-weight: $weight-600;
    }

    h2 {
        font-weight: $weight-600;
        color: $color-black-100;
        margin: 0px;
    }

    h3 {
        font-weight: $weight-600;
        color: $color-black-100;
        margin: 0px;
    }

    h4 {
        font-size: $size-13;
        font-weight: $weight-600;
        color: $color-black-100;
        margin: 0px;
    }
}

.metadata-field-link {
    h4 {
        font-size: $size-13;
        font-weight: $weight-600;
        // color: $color-black-100;
        margin: 0px;
        cursor: pointer;
        color: var(--Blue-1, var(--Colors-Text-Primary, #2F80ED));
    }
}

// Snakbar
.mat-simple-snackbar {
    font-size: $size-14;
    font-weight: $weight-500;
}

.success-snackbar {
    background-color: $color-white;
    border-radius: 0px 0px 0px 0px !important;
    // @include box-shadow(0px 2px 10px 0px rgba(0, 0, 0, 0.1));
    border-left: 4px solid $color-green;

    span {
        color: $color-black-100;
        font-weight: $weight-700;
    }

    button {
        color: $color-black-100;
        font-size: $size-14;
        font-weight: $weight-700;
    }
}

.error-snackbar {
    background-color: $color-white;
    border-radius: 0px 0px 0px 0px !important;
    // @include box-shadow(0px 2px 10px 0px rgba(0, 0, 0, 0.1));
    border-left: 4px solid $color-red;

    span {
        color: $color-red;
        font-weight: $weight-700;
    }

    button {
        border: 1px solid $color-border;
        color: $color-white-100;
        font-size: $size-14;
        font-weight: $weight-700;
    }
}

.empty-container {
    display: block;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;

    .empty-position {
        width: 100%;
        height: 100%;
        position: relative;
        top: 10%;
        left: 40%;

        .empty-icon {
            justify-content: center;
            align-items: center;

            .mat-icon {
                font-size: 120px;
            }
        }

        .empty-text {
            justify-content: center;
            align-items: center;
        }
    }

}

.chat-container {
    margin: 5px;
    display: block;

    .message {

        // padding: 0;
        // margin: 0;
        span {
            font-size: $size-12 !important;
            font-weight: $weight-600 !important;
            color: $color-black;
        }

        .message-content {
            margin-bottom: 10px;
            padding: 5px 10px 5px 10px !important;
            border-radius: 5px !important;
            border: 1px solid rgba(111, 111, 111, 0.20);
            display: inline-block;

            p {
                display: block;
                font-size: $size-13 !important;
                font-weight: $weight-600 !important;
                color: $color-black;
                margin: 0;
                padding-bottom: 5px;
            }

            span {

                font-size: $size-11 !important;
                font-weight: $weight-600 !important;
                color: $color-black;
                white-space: pre;

                small {
                    font-size: $size-12 !important;
                    font-weight: $weight-500 !important;
                    color: $color-black-200;

                }

            }

        }
    }
}




.unit-message {

    margin: 5px;
    display: inline-block;

    span {
        font-size: $size-12 !important;
        font-weight: $weight-600 !important;
        color: $color-black;
    }

    .message-content {
        margin-bottom: 10px;
        padding: 5px 10px 5px 10px !important;
        border-radius: 5px !important;
        border: 1px solid rgba(111, 111, 111, 0.20);
        display: inline-block;

        p {
            display: block;
            font-size: $size-13 !important;
            font-weight: $weight-600 !important;
            color: $color-black;
            margin: 0;
            padding-bottom: 5px;
        }

        span {

            font-size: $size-11 !important;
            font-weight: $weight-600 !important;
            color: $color-black;
            white-space: pre;

            small {
                font-size: $size-12 !important;
                font-weight: $weight-500 !important;
                color: $color-black-200;

            }

        }

    }
}

.mat-hint{
    font-size: $size-11 !important;
    font-weight: $weight-500 !important;
    color: $color-black-200 !important;
}